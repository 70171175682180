import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Content from "../components/content"

const IndexPage = ({location, data}) => {
  return (
    <Layout
      pageTitle="The National Commercial Vehicle and Cargo Theft Prevention Task Force (NCTTF)" pathName={location.pathname} hasBreadcrumbs={false}>
      <SEO title="The National Commercial Vehicle and Cargo Theft Prevention Task Force (NCTTF)" />
      <Content>
        {/*
          alt intentionally left blank
          image not meant to be annoucned
        */}
        <Img fluid={data.homeImage.childImageSharp.fluid} alt="" />
        <p>We work in cooperation with private industry, insurance, and federal, state and local governments to combat the continued threat posed by cargo theft perpetrators to the economy, the American citizens, and the national security of the United States of America.</p>
        <p>The task force members have identified a need for additional information sharing and national partnering. This website is one step in strengthening this collaborative fight in combating cargo theft. In addition, the task force meets once a year for an annual National Cargo Theft Summit to discuss national strategy, patterns and practices of cargo thieves, recent investigative case studies, and how to combat cargo theft.</p>
      </Content>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    homeImage: file(relativePath: { eq: "home-2x.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
